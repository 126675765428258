<template>
  <div>
    <el-dialog
      :title="isAdd ? '新增' : '修改'"
      :visible.sync="addDialogVisible"
      @close="close('form')"
    >
      <el-form
        label-width="140px"
        :model="itemForm"
        :rules="rules"
        class="form-class"
        ref="form"
      >
        <el-form-item label="图标：">
          <el-upload
            class="avatar-uploader"
            :action="$uploadURL"
            :show-file-list="false"
            :on-success="handleUploadSuccess"
          >
            <img
              v-if="itemForm.pollingUrl"
              :src="itemForm.pollingUrl"
              class="avatar"
              style="width:100px;height:100px"
            />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="巡检名称：" prop="pollingName">
          <el-input
            v-model="itemForm.pollingName"
            placeholder="请输入"
          ></el-input>
        </el-form-item>
        <el-form-item label="巡检内容描述：" prop="pollingContent">
          <el-input
            v-model="itemForm.pollingContent"
            placeholder="请输入"
          ></el-input>
        </el-form-item>
        <el-form-item label="创建时间：" prop="creationtime">
          <el-date-picker
            v-model="itemForm.creationtime"
            type="datetime"
            placeholder="选择创建时间"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitForm('form')">提交</el-button>
          <el-button @click="resetForm('form')">重置</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
export default {
  props: {
    // 0编辑 1新增
    isAdd: {
      typeof: Number,
      default: null
    },
    queryOneId: {
      typeof: Number,
      default: null
    }
  },
  mounted() {
    // 获取详情
    if (this.isAdd === 0 && this.queryOneId) {
      this.getDetail();
    }
  },
  data() {
    return {
      addDialogVisible: true,
      itemForm: {},
      rules: {
        pollingName: [{ required: true, message: "请输入巡检名称" }],
        pollingContent: [{ required: true, message: "请输入内容" }],
        creationtime: [
          { required: true, message: "请选择创建时间", trigger: "blur" }
        ]
      }
    };
  },
  methods: {
    //图片上传成功处理
    handleUploadSuccess(res, file) {
      this.itemForm.pollingUrl = file.response.data.url;
      console.log("上传", this.itemForm.pollingUrl, res, file);
    },
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.submit();
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    close(formName) {
      this.addDialogVisible = false;
      this.resetForm(formName);
      this.$emit("closeAddDialog");
    },
    async submit() {
      let that = this;
      let res = await this.$http.post("pollingContent/save", {
        ...this.itemForm,
        pollingId: this.$route.query.id
      });
      if (res.data.code == 200) {
        if (this.isAdd) {
          that.$notify.success({
            title: "提示",
            message: "添加成功",
            showClose: true
          });
        } else {
          that.$notify.success({
            title: "提示",
            message: "修改成功",
            showClose: true
          });
        }
        this.close();
      } else {
        this.$message.error(res.data.message);
      }
    },
    async getDetail() {
      let res = await this.$http.post("pollingContent/queryOne", {
        id: this.queryOneId
      });
      if (res.data.code == 200) {
        this.itemForm = res.data.data;
      } else {
        this.$message.error(res.data.message);
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.form-class {
  /deep/ .el-upload {
    border: 1px dashed #adaaaa;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  /deep/ .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 100px;
    height: 100px;
    line-height: 100px;
    text-align: center;
  }
  /deep/ .el-form-item__label {
    box-sizing: border-box;
    font-family: "Arial", sans-serif;
    color: #333333;
    font-size: 15px;
    width: 150px;
  }
  /deep/ .el-input__inner {
    box-sizing: border-box;
    font-family: "Arial", sans-serif;
    color: #333333;
    font-size: 13px;
  }
}
/deep/ .el-icon-close:before {
  color: #333333;
}
</style>
