<template>
    <div class="body">
        <div class="home-class">
            <div class="back-class" @click="back">
                <i class="el-icon-arrow-left"></i>
                <div>返回</div>
            </div>
            <div class="title-class">
                <div class="icon-class"></div>
                <div class="text-class">巡检计划</div>
            </div>
            <el-form label-width="120px" :model="itemForm" :inline="true" class="form-class">
                <el-form-item label="编号：" prop="pollingNumber">
                    <el-input v-model="itemForm.pollingNumber" placeholder="请输入"></el-input>
                </el-form-item>
                <el-form-item label="巡视类型：" prop="pollingType">
                    <el-input v-model="itemForm.pollingType" placeholder="请输入"></el-input>
                </el-form-item>
            </el-form>
            <div class="title-class">
                <div class="icon-class"></div>
                <div class="text-class">巡检项配置</div>
            </div>
            <div class="line-class">
                <el-form :inline="true" :model="formInline" class="demo-form-inline">
                    <el-form-item label="巡检项名称：">
                        <el-input v-model="formInline.pollingName" placeholder="请输入巡检项名称"></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="onSubmit">查询</el-button>
                    </el-form-item>
                </el-form>
                <div>
                    <el-button type="primary" @click="editClick(1)">新增</el-button>
                    <el-button type="primary" @click="editClick(0)">修改</el-button>
                    <el-button type="primary" @click="deleteClick">删除</el-button>
                    <el-button type="primary" @click="downloadExcelTemplate">下载模板</el-button>
                    <input type="file" id="default-btn" @change="importClick">
                    <el-button type="primary" @click="exportClick">导出</el-button>
                </div>
            </div>
            <el-table :data="tableData" highlight-current-row style="width: 100%"
                @selection-change="handleSelectionChange">
                <el-table-column type="selection" width="55">
                </el-table-column>
                <el-table-column label="图标" align="center">
                    <template slot-scope="scope">
                        <img :src="scope.row.pollingUrl" alt="">
                    </template>
                </el-table-column>
                <el-table-column label="巡检名称" align="center">
                    <template slot-scope="scope">
                        <span>{{ scope.row.pollingName }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="巡检内容描述" align="center">
                    <template slot-scope="scope">
                        <span>{{ scope.row.pollingContent }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="创建时间" align="center">
                    <template slot-scope="scope">
                        <span>{{ scope.row.creationtime }}</span>
                    </template>
                </el-table-column>
            </el-table>
            <!-- 分页区域 -->
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                :current-page="currentPage" :page-sizes="[2, 5, 10, 15]" :page-size="size"
                layout="total, sizes, prev, pager, next, jumper" :total="total"></el-pagination>
        </div>

        <div v-if="addDialogVisible == true">
            <add-class-list-dialog :addDialogVisible="addDialogVisible" :isAdd="isAdd" :queryOneId="queryOneId" 
                @closeAddDialog="closeAddDialog"></add-class-list-dialog>
        </div>
    </div>
</template>
<script>
import AddClassListDialog from './dialog/addClassListDialog.vue'
export default {
    components: {
        AddClassListDialog
    },
    data() {
        return {
            itemForm: {},
            formInline: {
                powerStationName: localStorage.getItem("powerStationName"),
                pollingId: '',
            },
            total: 0,
            size: 10,
            currentPage: 1,
            tableData: [],
            addDialogVisible: false, //是否显示新增/编辑弹框
            isAdd: 1, // 0编辑 1新增
            isBan: true,
            queryOneId: null
        }
    },
    mounted() {
        this.getDetail(),
            this.getTableList()
    },
    methods: {
        back() {
            this.$router.go(-1)
        },
        handleSizeChange(val) {
            this.size = val
            this.getTableList()
        },
        handleCurrentChange(val) {
            this.currentPage = val
            this.getTableList()
        },
        // 查询
        onSubmit() {
            this.getTableList()
        },
        // 新增/编辑
        editClick(key) {
            if (key === 0 && this.isBan) return
            this.isAdd = key
            this.addDialogVisible = true
        },
        // 关闭弹框
        closeAddDialog() {
            this.addDialogVisible = false
            this.getTableList()
        },
        //表格勾选
        handleSelectionChange(val) {
            this.selectList = []
            val.forEach((item) => {
                this.selectList.push(item.id)
            })
            if (val.length != 1) {
                this.isBan = true
            } else {
                this.isBan = false
                this.queryOneId = val[0].id
            }
        },
        // 导出 
        exportClick() {
            this.$http.post("pollingContent/Exp").then(res => {
                if (res.data.code == 200) {
                    window.open(res.data.data.data);
                }
            });
        },
        async importClick(event) {
            const file = event.target.files[0];
            // 使用 FormData 包装文件
            const formData = new FormData();
            formData.append('file', file);
            const response = await this.$http.post("pollingContent/Exp2", formData, {
                headers: {
                    'Content-Type': 'multipart/form-data', // 设置正确的Content-Type
                },
            });
            console.log('二进制文件', response)
        },
        // 下载模版
        downloadExcelTemplate() {
            const url = '/excel/pollingContent.xlsx'
            const xhr = new XMLHttpRequest()
            xhr.open('GET', url, true)
            xhr.responseType = 'blob'
            xhr.onload = () => {
                if (xhr.status === 200) {
                    const blob = new Blob([xhr.response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
                    const url = URL.createObjectURL(blob)
                    const link = document.createElement('a')
                    link.href = url
                    link.download = 'pollingContent.xlsx'
                    link.click()
                    URL.revokeObjectURL(url)
                }
            }
            xhr.send()
        },
        //删除
        async deleteClick() {
            if (this.selectList.length < 1) return
            let delList = this.selectList
            let res = await this.$http.post('pollingContent/deleteBatch', [...delList])
            if (res.data.code == 200) {
                this.$message.success('删除成功')
                this.getTableList()
            } else {
                this.$message.error(res.data.message)
            }
        },
        async getDetail() {
            let res = await this.$http.post('polling/queryOne', { id: this.$route.query.id })
            if (res.data.code == 200) {
                this.itemForm = res.data.data
            } else {
                this.$message.error(res.data.message)
            }
        },
        async getTableList() {
            this.formInline.pollingId = this.$route.query.id
            let params = {
                "condition": this.formInline,
                "currPage": this.currentPage,
                "pageSize": this.size
            }
            let res = await this.$http.post('pollingContent/list', params)
            if (res.data.code == 200) {
                this.tableData = res.data.data.data
                this.total = res.data.data.count
            } else {
                this.$message.error(res.data.message)
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.body {
    background: #223f6c;
    height: 92.8vh;
    padding: 6px;

    .line-class {
        display: flex;
        justify-content: space-between;
        margin-left: 20px;
    }

    #default-btn::file-selector-button {
        padding: 5.5px 10px;
        background-color: #409EFF;
        border: 1px solid #409EFF;
        border-radius: 3px;
        cursor: pointer;
        color: #fff;
        font-size: 12px;
    }

    #default-btn {
        font-size: 0;
        margin-left: 10px;
        margin-right: 6px;
        margin: 1px 6px 0 10px;

    }


    .home-class {
        height: 100%;
        padding: 2px 2px 2px 2px;
        background-color: #2d5981;
        box-sizing: border-box;
        padding: 8px;

        .back-class {
            cursor: pointer;
            display: flex;
            align-items: center;
            font-size: 15px;
            color: #fff;
            margin-bottom: 20px;
        }

        .title-class {
            display: flex;
            align-items: center;
            margin-bottom: 20px;

            .icon-class {
                width: 5px;
                height: 23px;
                border-radius: 5px;
                border: 1px solid #02a7f0;
                background-color: #02a7f0;
            }

            .text-class {
                box-sizing: border-box;
                font-family: "Arial", sans-serif;
                color: #fff;
                font-size: 16px;
                margin-left: 10px;
            }
        }
    }
}

/deep/ .el-form-item__label {
    background-color: rgba(255, 255, 255, 0);
    box-sizing: border-box;
    font-family: "微软雅黑", sans-serif;
    color: #ffffff;
    text-align: right;
    font-size: 15px;
}

/deep/ .el-input__inner {
    border-radius: 5px;
    border: 1px solid rgba(151, 179, 203, 0.55);
    background-color: rgba(255, 255, 255, 0);
    box-sizing: border-box;
    font-family: "Arial", sans-serif;
    color: #f2f2f2;
    text-align: left;
}

/deep/ .el-table--border,
.el-table--group {
    border: 1px solid rgba(151, 179, 203, 0.55);
}

/deep/ .el-table--border th {
    background-color: #21527e;

    border: 1px solid rgba(151, 179, 203, 0.55);
    box-sizing: border-box;
    font-family: "微软雅黑", sans-serif;
    color: #ffffff;
}

/deep/ .el-table th {
    background-color: #2d5981;
    border: 1px solid rgba(151, 179, 203, 0.55);
    box-sizing: border-box;
    font-family: "微软雅黑", sans-serif;
    color: #ffffff;
}

/deep/ .el-table td {
    background-color: #2d5981;
    border: 1px solid rgba(151, 179, 203, 0.55);
    box-sizing: border-box;
    font-family: "微软雅黑", sans-serif;
    color: #ffffff;
}

/deep/ .el-table__body-wrapper {
    background: #2d5981;
}

::v-deep .el-table--enable-row-hover .el-table__body tr:hover>td {
    background-color: #223f6c !important;
}

/deep/ .el-pagination {
    display: flex;
    justify-content: flex-end;
}

/deep/ .el-pagination button:disabled {
    background: rgba(255, 255, 255, 0);
}

/deep/ .el-pager li.active {
    color: #f2f2f2;
    background-color: #00b2e6;
    cursor: default;
}

/deep/ .el-pager li {
    background: none;
    color: #f2f2f2;
}

/deep/ .el-icon {
    color: #fff;
}

/deep/ .el-pagination .btn-next {
    background: none;
}

/deep/ .el-pagination__total {
    color: #fff;
    box-sizing: border-box;
    font-family: "Arial", sans-serif;
    font-size: 12px;
}

/deep/ .el-pagination__jump {
    color: #fff;
    box-sizing: border-box;
    font-family: "Arial", sans-serif;
    font-size: 15px;
}
</style>